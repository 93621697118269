import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/img/datepicker-icon.svg';
import {
  AUTHORITY2,
  DELIVERY_STATUS,
  PROCESSING_TYPE_OPTIONS,
  UNIT_TYPES,
  WORK_ORDER_DISPLAY_STATUS,
} from '../../shared/Constants/Constants';
import { convertToKG, toCapitalize } from '../../shared/Utils/Helper';
import { browseNodeSelectors } from '../../state/BrowseNode';
import {
  DistrictCell,
  ExecutionModeCell,
  FormattedDateCell,
  FulfillmentCycleCell,
  HsnSacCodeCell,
  MaterialCategoryCell,
  MaterialCell,
  StateCell,
} from './TableCells/TableCells';

import { useCustomers } from '../../hooks';
import { Region } from '../../shared/Components/GenericComponent';
import HasAuthority, { useAuthority } from '../../shared/Components/HasAuthority/HasAuthorityNew';
import { roundOffAmount } from '../../shared/Utils/Utils';
import { getNumberFomatWithoutRoundOff } from '../../views/RecyclersList/RecyclerColDef';
import ColumnFilters from '../../views/WorkOrder/ColumnFilters';

export const WORK_ORDER_ITEMS_DEPTH_ACCESSOR_CLASSNAME_MAP = {
  1: {
    fulfillmentCycle: 'cell-stat-review',
    pricePerUnit: 'cell-stat-reject',
    lineTotal: 'cell-stat-no-doc',
    status: 'cell-stat-approved',
    expander: 'delivery-table-heading',
  },
};

export const EDITABLE_BRAND_WO_STATUSES = ['REJECTED', 'DRAFT'];

export const WORK_ORDER_TABLE_COLUMNS = {
  WORK_ORDERS: function (props) {
    const browseNodes = useSelector(({ browseNodes }) => [...browseNodes.data]);
    const { serviceProviders } = useSelector(({ serviceProvider }) => ({
      serviceProviders: serviceProvider.listLight,
    }));
    return [
      {
        Header: 'Work order Id',
        accessor: 'id',
        Cell: (cellInfo) => {
          const { hasAuth } = useAuthority();

          const isBrand = hasAuth([AUTHORITY2.BRAND]);

          return (
            <HasAuthority
              permissionsAllowed={[
                {
                  groupName: 'Manage_Vendors',
                  moduleName: 'Work_Orders',
                  name: 'can_view',
                },
              ]}
              failureComponent={<span>{cellInfo?.cell?.value}</span>}>
              <div className="btn-align" style={{ fontFamily: 'Inter' }}>
                {!cellInfo.row.depth ? (
                  <React.Fragment>
                    <Button
                      className="p-0 m-0"
                      variant="link"
                      onClick={() => {
                        if (cellInfo?.row?.isExpanded) {
                          cellInfo.row.toggleRowExpanded(false);
                        } else {
                          props.onSubRowShow(cellInfo.row);
                          cellInfo.row.toggleRowExpanded(true);
                        }
                      }}>
                      {cellInfo?.row?.isExpanded ? (
                        <a className="hide-btn mr-2">-</a>
                      ) : (
                        <a className="hide-btn mr-2">+</a>
                      )}
                    </Button>
                  </React.Fragment>
                ) : (
                  browseNodeSelectors.getItemByID(browseNodes, cellInfo?.cell?.value)?.name
                )}
                {!cellInfo.row.depth ? (
                  <NavLink
                    to={
                      isBrand
                        ? '/work-orders-brandPRO/' + cellInfo?.row?.values?.id
                        : '/work-orders/' + cellInfo?.row?.values?.id
                    }>
                    <u>{cellInfo?.cell?.value}</u>
                  </NavLink>
                ) : null}
              </div>
            </HasAuthority>
          );
        },
      },
      {
        Header: 'Start date',
        accessor: 'fromDate',
        Cell: (cellInfo) => {
          return !cellInfo.row.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <Region stateId={cellInfo.row.original.stateId} districtId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Execution mode',
        accessor: 'executionMode',
        Cell: (cellInfo) => {
          return !cellInfo.row.depth ? (
            <ExecutionModeCell executionMode={cellInfo?.cell?.value} />
          ) : (
            <span>
              {cellInfo.cell.value} {UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: 'Vendors',
        accessor: 'toCustomerId',
        Cell: (cellInfo) => {
          const provider = serviceProviders.find((e) => e.id === cellInfo.cell.value);
          return !cellInfo.row.depth ? (
            <span>{provider?.businessName}</span>
          ) : (
            <span>
              {cellInfo.cell.value} {UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: 'Fulfillment Year',
        Cell: (cellInfo) => {
          const CELL_INFO = cellInfo?.row?.original;
          const START_DATE = new Date(CELL_INFO?.fulfillmentYearStartDate ?? '').getFullYear();
          const END_DATE = new Date(CELL_INFO?.fulfillmentYearEndDate ?? '').getFullYear();
          const CELL_VALUE = `${isNaN(START_DATE) ? '' : START_DATE} - ${
            isNaN(END_DATE) ? '' : END_DATE
          }`;
          return !cellInfo.row.depth ? (
            CELL_VALUE
          ) : (
            <span>
              {convertToKG(cellInfo?.cell?.row?.original?.creditsTransfered ?? 0)}{' '}
              {UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: 'Created Date',
        accessor: 'createdOn',
        Cell: (cellInfo) => {
          return <FormattedDateCell date={cellInfo?.cell?.value} />;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellInfo) => {
          // return <p>{  toCapitalize(cellInfo?.cell?.value)}</p>;
          return !cellInfo?.row?.depth ? (
            <Badge
              style={{
                backgroundColor: WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.bg,
                color: WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.textColor,
                border: `1px solid ${
                  WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.borderColor
                }`,
                borderRadius: '16px',
                padding: '6px',
                fontSize: '12px',
                fontWeight: 500,
                textAlign: 'center',
                fontFamily: 'Inter',
              }}>
              {WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.displayText}
            </Badge>
          ) : (
            cellInfo.cell.value
          );
        },
      },
      /* {
         Header: "Downloads", Cell: ({cell: {value}}) => {
           return <div>
             <NavLink to={'/work-orders'}>Work Order</NavLink>
             <NavLink className={'pl-3'} to={'/work-orders'}>Deliveries</NavLink>
           </div>;
         }
       },*/
      {
        Header: 'Actions',
        accessor: 'draft',
        Cell: (cellInfo) => {
          return cellInfo.row.depth ? null : (
            <div style={{ fontFamily: 'Inter' }}>
              {cellInfo?.cell?.row?.original?.draft ? (
                <div>
                  <HasAuthority
                    permissionsAllowed={[
                      {
                        groupName: 'Manage_Vendors',
                        moduleName: 'Work_Orders',
                        name: 'can_view',
                      },
                    ]}>
                    <NavLink className={'pl-3'} to={'/work-orders/' + cellInfo?.row?.values?.id}>
                      View
                    </NavLink>
                  </HasAuthority>
                  <HasAuthority
                    permissionsAllowed={[
                      {
                        groupName: 'Manage_Vendors',
                        moduleName: 'Work_Orders',
                        name: 'can_edit',
                      },
                    ]}>
                    {cellInfo?.cell?.row?.original?.status !== 'CLOSED' && (
                      <NavLink
                        className={'pl-3'}
                        to={'/work-orders/create/' + cellInfo?.row?.values?.id}>
                        Edit
                      </NavLink>
                    )}
                  </HasAuthority>
                </div>
              ) : (
                <HasAuthority
                  permissionsAllowed={[
                    {
                      groupName: 'Manage_Vendors',
                      moduleName: 'Work_Orders',
                      name: 'can_view',
                    },
                  ]}>
                  <NavLink className={'pl-3'} to={'/work-orders/' + cellInfo?.row?.values?.id}>
                    View
                  </NavLink>
                </HasAuthority>
              )}
            </div>
          );
        },
      },
    ];
  },
  WORK_ORDERS_INCOMING: function (props) {
    const browseNodes = useSelector(({ browseNodes }) => [...browseNodes.data]);
    const { clients } = useSelector(({ account }) => ({
      clients: account.clients,
    }));
    const customers = useCustomers();
    return [
      {
        Header: 'Work order Id',
        accessor: 'id',
        Cell: (cellInfo) => {
          return (
            <HasAuthority
              permissionsAllowed={[
                { groupName: 'Execute_Targets', moduleName: 'Work_Orders', name: 'can_view' },
              ]}
              failureComponent={<span>{cellInfo?.cell?.value}</span>}>
              <div className="btn-align">
                {!cellInfo.row.depth ? (
                  <React.Fragment>
                    <Button
                      className="p-0 m-0"
                      variant="link"
                      onClick={() => {
                        if (cellInfo?.row?.isExpanded) {
                          cellInfo.row.toggleRowExpanded(false);
                        } else {
                          props.onSubRowShow(cellInfo.row);
                          cellInfo.row.toggleRowExpanded(true);
                        }
                      }}>
                      {cellInfo?.row?.isExpanded ? (
                        <a className="hide-btn">-</a>
                      ) : (
                        <a className="hide-btn">+</a>
                      )}
                    </Button>
                  </React.Fragment>
                ) : (
                  browseNodeSelectors.getItemByID(browseNodes, cellInfo?.cell?.value)?.name
                )}
                {!cellInfo.row.depth ? (
                  <NavLink
                    className={'pl-2'}
                    to={'/work-orders-incoming/' + cellInfo?.row?.values?.id}>
                    <u>{cellInfo?.cell?.value}</u>
                  </NavLink>
                ) : null}
              </div>
            </HasAuthority>
          );
        },
      },
      {
        Header: 'Start date',
        accessor: 'fromDate',
        Cell: (cellInfo) => {
          return !cellInfo.row.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <Region stateId={cellInfo.row.original.stateId} districtId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Client name',
        accessor: 'fromCustomerId',
        Cell: (cellInfo) => {
          const clientName = clients.find((client) => client.id === cellInfo?.cell?.value)?.name;
          return !cellInfo.row.depth ? (
            clientName || cellInfo?.cell?.value
          ) : (
            <span>
              {' '}
              {cellInfo.cell.row?.original?.fromCustomerId}
              {UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: 'Fulfillment Year',
        Cell: (cellInfo) => {
          const CELL_INFO = cellInfo?.row?.original;
          const START_DATE = new Date(CELL_INFO?.fulfillmentYearStartDate ?? '').getFullYear();
          const END_DATE = new Date(CELL_INFO?.fulfillmentYearEndDate ?? '').getFullYear();
          const CELL_VALUE = `${isNaN(START_DATE) ? '' : START_DATE} - ${
            isNaN(END_DATE) ? '' : END_DATE
          }`;
          return !cellInfo.row.depth ? (
            CELL_VALUE
          ) : (
            <span>
              {' '}
              {cellInfo.cell.row?.original?.workOrderItems}
              {UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: 'No. of Targets',
        accessor: 'workOrderItems',
        Cell: (cellInfo) => {
          return cellInfo.row.depth ? (
            <span>
              {' '}
              {convertToKG(cellInfo.cell.row?.original?.creditsAssigned ?? 0)}
              {UNIT_TYPES['KG']?.displayText}
            </span>
          ) : (
            cellInfo?.cell?.value.length
          );
        },
      },

      {
        Header: 'Created Date',
        accessor: 'createdOn',
        Cell: (cellInfo) => {
          return <FormattedDateCell date={cellInfo?.cell?.value} />;
        },
      },
      {
        Header: 'Actions',
        accessor: 'draft',
        Cell: (cellInfo) => {
          return cellInfo.row.depth ? (
            <span> {cellInfo.cell.value} </span>
          ) : (
            <div>
              <HasAuthority
                permissionsAllowed={[
                  { groupName: 'Execute_Targets', moduleName: 'Work_Orders', name: 'can_view' },
                ]}>
                <NavLink
                  className={'pl-3'}
                  to={'/work-orders-incoming/' + cellInfo?.row?.values?.id}>
                  View
                </NavLink>
              </HasAuthority>
            </div>
          );
        },
      },
    ];
  },
  BRAND_WO_PRO: function (props) {
    const browseNodes = useSelector(({ browseNodes }) => [...browseNodes.data]);
    const { clients } = useSelector(({ account }) => ({
      clients: account.clients,
    }));
    return [
      {
        Header: 'Work order Id',
        accessor: 'id',
        Cell: (cellInfo) => {
          return (
            <HasAuthority
              permissionsAllowed={[
                { groupName: 'Execute_Targets', moduleName: 'Work_Orders', name: 'can_view' },
              ]}
              failureComponent={<span>{cellInfo?.cell?.value}</span>}>
              <div className="btn-align">
                {!cellInfo.row.depth ? (
                  <React.Fragment>
                    <Button
                      className="p-0 m-0"
                      variant="link"
                      onClick={() => {
                        if (cellInfo?.row?.isExpanded) {
                          cellInfo.row.toggleRowExpanded(false);
                        } else {
                          props.onSubRowShow(cellInfo.row);
                          cellInfo.row.toggleRowExpanded(true);
                        }
                      }}>
                      {cellInfo?.row?.isExpanded ? (
                        <a className="hide-btn">-</a>
                      ) : (
                        <a className="hide-btn">+</a>
                      )}
                    </Button>
                  </React.Fragment>
                ) : (
                  browseNodeSelectors.getItemByID(browseNodes, cellInfo?.cell?.value)?.name
                )}
                {!cellInfo.row.depth ? (
                  <NavLink
                    className={'pl-2'}
                    to={'/work-orders-brandPRO/' + cellInfo?.row?.values?.id}>
                    <u>{cellInfo?.cell?.value}</u>
                  </NavLink>
                ) : null}
              </div>
            </HasAuthority>
          );
        },
      },
      {
        Header: 'Start date',
        accessor: 'fromDate',
        Cell: (cellInfo) => {
          return !cellInfo.row.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <Region stateId={cellInfo.row.original.stateId} districtId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Brand name',
        accessor: 'fromCustomerId',
        Cell: (cellInfo) => {
          const clientName = clients.find((client) => client.id === cellInfo?.cell?.value)?.name;
          return !cellInfo.row.depth ? (
            clientName || cellInfo?.cell?.value
          ) : (
            <span>
              {' '}
              {cellInfo.cell.value}
              {UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: 'No. of Targets',
        accessor: 'workOrderItems',
        Cell: (cellInfo) => {
          return cellInfo.row.depth ? (
            <span>
              {' '}
              {cellInfo.cell.value}
              {UNIT_TYPES['KG']?.displayText}
            </span>
          ) : (
            cellInfo?.cell?.value.length
          );
        },
      },
      {
        Header: 'Fulfillment Year',
        Cell: (cellInfo) => {
          const CELL_INFO = cellInfo?.row?.original;
          const START_DATE = new Date(CELL_INFO?.fulfillmentYearStartDate ?? '').getFullYear();
          const END_DATE = new Date(CELL_INFO?.fulfillmentYearEndDate ?? '').getFullYear();
          const CELL_VALUE = `${isNaN(START_DATE) ? '' : START_DATE} - ${
            isNaN(END_DATE) ? '' : END_DATE
          }`;
          return CELL_VALUE;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellInfo) => {
          // return <p>{  toCapitalize(cellInfo?.cell?.value)}</p>;
          return (
            <Badge
              style={{
                backgroundColor: WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.bg,
                color: WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.textColor,
                border: `1px solid ${
                  WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.borderColor
                }`,
                borderRadius: '16px',
                padding: '6px',
                fontSize: '12px',
                fontWeight: 500,
                textAlign: 'center',
              }}>
              {WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.displayText}
            </Badge>
          );
        },
      },
    ];
  },
  WORK_ORDER_ITEMS: function (props) {
    return [
      {
        Header: 'Material category ',
        accessor: 'browseNodeId',
        Cell: (cellInfo) => {
          return (
            <div className="btn-align">
              {!cellInfo.row.depth ? (
                <React.Fragment>
                  <Button
                    variant="link"
                    disabled={!(cellInfo.row.original.fulfilledQty > 0)}
                    className={!(cellInfo.row.original.fulfilledQty > 0) ? 'opacity 0.5' : ''}
                    onClick={() => {
                      if (cellInfo?.row?.isExpanded) {
                        cellInfo.row.toggleRowExpanded(false);
                      } else {
                        props.onSubRowShow(cellInfo);
                        cellInfo.row.toggleRowExpanded(true);
                      }
                    }}>
                    {cellInfo?.row?.isExpanded ? (
                      <a className="hide-btn">-</a>
                    ) : (
                      <a className="hide-btn">+</a>
                    )}
                  </Button>
                </React.Fragment>
              ) : null}
              <MaterialCategoryCell
                browseNodes={props?.browseNodes}
                browseNodeId={cellInfo?.cell?.value}
              />
            </div>
          );
        },
      },
      {
        Header: 'Material',
        accessor: 'itemId',
        Cell: (cellInfo) => {
          return (
            <MaterialCell
              browseNodes={props?.browseNodes}
              browseNodeId={cellInfo?.row?.values?.browseNodeId}
              materialId={cellInfo?.cell?.value}
            />
          );
        },
      },
      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <StateCell regions={props?.regions} stateId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'District',
        accessor: 'districtId',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <DistrictCell
              regions={props?.regions}
              stateId={cellInfo?.row?.values?.stateId}
              districtId={cellInfo?.cell?.value}
            />
          );
        },
      },
      {
        Header: () => <span className={'font-bold'}>Target Qty </span>,
        accessor: 'qty',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <span className={'font-bold'}>
              {cellInfo?.cell?.value}&nbsp;{UNIT_TYPES['KG']?.displayText}
            </span>
          ),
      },
      {
        Header: () => <span className={'font-bold'}>Fulfilled Qty</span>,
        accessor: 'fulfilledQty',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            DELIVERY_STATUS[cellInfo?.cell?.value]?.displayText || cellInfo?.cell?.value
          ) : (
            <span className={'font-bold'}>
              {cellInfo?.cell?.value}&nbsp;{UNIT_TYPES['KG']?.displayText}
            </span>
          ),
      },
      {
        Header: () => <span className={'font-bold'}>Credits Assigned</span>,
        accessor: 'creditsAssigned',
        Cell: (cellInfo) => <span className={'font-bold'}>{cellInfo?.cell?.value * 1000} Kg</span>,
      },
      {
        Header: () => {
          return (
            <>
              Delivery date
              <DatePickerIcon className={'ml-2 float-right'} height={15} />
            </>
          );
        },
        accessor: 'estimatedDelivery',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <p>{cellInfo?.cell?.value}</p>
          ) : (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Fulfillment',
        accessor: 'fulfillmentCycle',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <div>{cellInfo?.cell?.value}</div>
          ) : (
            <FulfillmentCycleCell fulfillmentCycleId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Price per unit',
        accessor: 'pricePerUnit',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <span className={'font-bold'}>
              <FontAwesomeIcon icon={faRupeeSign} /> {cellInfo?.cell?.value}
            </span>
          ),
      },
      {
        Header: 'Total price',
        accessor: 'lineTotal',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <span className={'font-bold'}>
              <FontAwesomeIcon icon={faRupeeSign} /> {cellInfo?.cell?.value}
            </span>
          ),
      },
      {
        Header: 'Po Line Item',
        accessor: 'poLineItem',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? cellInfo?.cell?.value : toCapitalize(cellInfo?.cell?.value),
      },
      {
        Header: 'Action',
        id: 'id',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <React.Fragment>
              <NavLink
                className={'pl-3'}
                to={'/executions/deliveries/' + cellInfo.cell.row?.original?.id}>
                View
              </NavLink>
              <NavLink className={'pl-3'} to={'#'}>
                Download
              </NavLink>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavLink className={'pl-3'} to={'#'}>
                Download
              </NavLink>
            </React.Fragment>
          );
        },
      },
    ];
  },
  WORK_ORDER_ITEMS_FOR_SP: function (props) {
    return [
      {
        id: 'check',
        Header: ' ',
        accessor: 'workOrderFor',
        Cell: (cellInfo) => {
          return (
            <div className="btn-align">
              {!cellInfo.row.depth ? (
                <React.Fragment>
                  <Button
                    variant="link"
                    className={'p-0 mr-2'}
                    disabled={!(cellInfo.row.original.fulfilledQty > 0)}
                    style={!(cellInfo.row.original.fulfilledQty > 0) ? { opacity: '0.5' } : {}}
                    onClick={() => {
                      if (cellInfo?.row?.isExpanded) {
                        cellInfo.row.toggleRowExpanded(false);
                      } else {
                        props.onSubRowShow(cellInfo);
                        cellInfo.row.toggleRowExpanded(true);
                      }
                    }}>
                    {cellInfo?.row?.isExpanded ? (
                      <a className="hide-btn">-</a>
                    ) : (
                      <a className="hide-btn">+</a>
                    )}
                  </Button>
                  {/* <ClientCell
                    clientId={cellInfo?.cell?.value}
                    clients={props?.account?.clients}
                    loggedInUserId={props.account.user.profile.id}
                  /> */}
                </React.Fragment>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: 'Material category ',
        accessor: 'browseNodeId',
        Cell: (cellInfo) => {
          return (
            <div className="">
              <MaterialCategoryCell
                browseNodes={props?.browseNodes}
                browseNodeId={cellInfo?.cell?.value}
              />
            </div>
          );
        },
      },
      {
        Header: 'Material',
        accessor: 'itemId',
        Cell: (cellInfo) => {
          // return cellInfo?.row?.depth ? <div>
          //   {cellInfo?.cell?.value}
          // </div> :
          return (
            <MaterialCell
              browseNodes={props?.browseNodes}
              browseNodeId={cellInfo?.row?.values?.browseNodeId}
              materialId={cellInfo?.cell?.value}
            />
          );
        },
      },
      {
        Header: 'HSN/SAC Code',
        accessor: 'sac',
        Cell: (cellInfo) => {
          return <HsnSacCodeCell cellInfo={cellInfo} isSac={cellInfo?.cell?.value} />;
        },
      },
      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            // <FormattedDateCell date={cellInfo?.cell?.value}/> :
            <StateCell regions={props?.regions} stateId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'District',
        accessor: 'districtId',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <DistrictCell
              regions={props?.regions}
              stateId={cellInfo?.row?.values?.stateId}
              districtId={cellInfo?.cell?.value}
            />
          );
        },
      },
      {
        Header: () => <span className={'font-bold'}>Target Qty </span>,
        accessor: 'qty',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <span className={'font-bold'}>
              {cellInfo?.cell?.value}&nbsp;{UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: () => <span className={'font-bold'}>Fulfilled Qty</span>,
        accessor: 'fulfilledQty',
        Cell: (cellInfo) => {
          if (cellInfo?.row?.depth) {
            const displayText =
              DELIVERY_STATUS[cellInfo?.cell?.value]?.displayText || cellInfo?.cell?.value;
            return displayText;
          } else {
            return (
              <span className={'font-bold'}>
                {cellInfo?.cell?.value}&nbsp;{UNIT_TYPES['KG']?.displayText}
              </span>
            );
          }
        },
      },
      {
        Header: () => <span className={'font-bold'}>Credits Transferred</span>,
        accessor: 'creditsAssigned',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <div></div>
          ) : (
            <div className={'font-bold'}>
              {Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3)) * 1000}&nbsp;
            </div>
          );
        },
      },
      {
        Header: () => {
          return (
            <>
              Delivery date
              <DatePickerIcon className={'ml-2 float-right'} height={15} />
            </>
          );
        },
        accessor: 'estimatedDelivery',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <p>{cellInfo?.cell?.value}</p>
          ) : (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Fulfillment',
        accessor: 'fulfillmentCycle',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <div>{cellInfo?.cell?.value}</div>
          ) : (
            <FulfillmentCycleCell fulfillmentCycleId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Price per unit',
        accessor: 'pricePerUnit',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <span className={'font-bold'}>
              <FontAwesomeIcon icon={faRupeeSign} /> {cellInfo?.cell?.value}
            </span>
          ),
      },
      {
        Header: 'Total price',
        accessor: 'lineTotal',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <span className={'font-bold'}>
              <FontAwesomeIcon icon={faRupeeSign} /> {cellInfo?.cell?.value}
            </span>
          ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? cellInfo?.cell?.value : toCapitalize(cellInfo?.cell?.value),
      },
      {
        Header: 'Action',
        id: 'expander',
        accessor: 'id',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <React.Fragment>
              <NavLink
                className={'pl-3'}
                to={`/executions/deliveries/${cellInfo.cell.row?.original?.id}`}>
                View
              </NavLink>
              <NavLink className={'pl-3'} to={'#'}>
                Download
              </NavLink>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavLink className={'pl-3'} to={'#'}>
                Download
              </NavLink>
            </React.Fragment>
          );
        },
      },
    ];
  },
  WORK_ORDER_ITEMS_INCOMING: function (props) {
    return [
      {
        Header: 'Material category',
        accessor: 'browseNodeId',
        Cell: (cellInfo) => {
          return (
            <div className="btn-align">
              {!cellInfo.row.depth ? (
                <React.Fragment>
                  <Button
                    variant="link"
                    className={'p-0 mr-2'}
                    disabled={!(cellInfo.row.original.fulfilledQty > 0)}
                    style={!(cellInfo.row.original.fulfilledQty > 0) ? { opacity: '0.5' } : {}}
                    onClick={() => {
                      if (cellInfo?.row?.isExpanded) {
                        cellInfo.row.toggleRowExpanded(false);
                      } else {
                        props.onSubRowShow(cellInfo);
                        cellInfo.row.toggleRowExpanded(true);
                      }
                    }}>
                    {cellInfo?.row?.isExpanded ? (
                      <a className="hide-btn">-</a>
                    ) : (
                      <a className="hide-btn">+</a>
                    )}
                  </Button>
                </React.Fragment>
              ) : null}

              <MaterialCategoryCell
                browseNodes={props?.browseNodes}
                browseNodeId={cellInfo?.cell?.value}
              />
            </div>
          );
        },
      },
      {
        Header: 'Material',
        accessor: 'itemId',
        Cell: (cellInfo) => {
          return (
            <MaterialCell
              browseNodes={props?.browseNodes}
              browseNodeId={cellInfo?.row?.values?.browseNodeId}
              materialId={cellInfo?.cell?.value}
            />
          );
        },
      },
      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <StateCell regions={props?.regions} stateId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'District',
        accessor: 'districtId',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <DistrictCell
              regions={props?.regions}
              stateId={cellInfo?.row?.values?.stateId}
              districtId={cellInfo?.cell?.value}
            />
          );
        },
      },
      {
        Header: () => <span className={'font-bold'}>Target Qty </span>,
        accessor: 'qty',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          ) : (
            <span className={'font-bold'}>
              {Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3))}&nbsp;
              {UNIT_TYPES['KG']?.displayText}
            </span>
          ),
      },
      {
        Header: () => <span className={'font-bold'}>Planned Qty</span>,
        accessor: 'plannedQty',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3))
          ) : (
            <span className={'font-bold'}>
              {Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3))}&nbsp;
              {UNIT_TYPES['KG']?.displayText}
            </span>
          );
        },
      },
      {
        Header: () => <span className={'font-bold'}>Fulfilled Qty</span>,
        accessor: 'fulfilledQty',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3))
          ) : (
            <span className={'font-bold'}>
              {Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3))}&nbsp;
              {UNIT_TYPES['KG']?.displayText}
            </span>
          ),
      },
      {
        Header: () => <span className={'font-bold'}>Credits Transferred</span>,
        accessor: 'creditsAssigned',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <div>{Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3)) * 1000}</div>
          ) : (
            <div className={'font-bold'}>
              {Number(parseFloat(cellInfo?.cell?.value || 0).toFixed(3)) * 1000}&nbsp;
            </div>
          );
        },
      },
      {
        Header: 'Credit Type',
        accessor: 'creditType',
        Cell: (cellInfo) => {
          const creditType = PROCESSING_TYPE_OPTIONS.find(
            (type) => type?.value === cellInfo?.value
          )?.label;
          return <span>{cellInfo?.value ? creditType : '-'}</span>;
        },
      },
      {
        Header: () => {
          return (
            <>
              Delivery date
              <DatePickerIcon className={'ml-2 float-right'} height={15} />
            </>
          );
        },
        accessor: 'estimatedDelivery',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <p>{cellInfo?.cell?.value}</p>
          ) : (
            <FormattedDateCell date={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Fulfillment',
        accessor: 'fulfillmentCycle',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <div>{cellInfo?.cell?.value}</div>
          ) : (
            <FulfillmentCycleCell fulfillmentCycleId={cellInfo?.cell?.value} />
          );
        },
      },
      {
        Header: 'Price per unit',
        accessor: 'pricePerUnit',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <span className={'font-bold'}>
              <FontAwesomeIcon icon={faRupeeSign} /> {cellInfo?.cell?.value}
            </span>
          ),
      },
      {
        Header: 'Total price',
        accessor: 'lineTotal',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? (
            cellInfo?.cell?.value
          ) : (
            <span className={'font-bold'}>
              <FontAwesomeIcon icon={faRupeeSign} />
              {roundOffAmount(cellInfo?.cell?.value)}
            </span>
          ),
      },
      {
        Header: 'Po Line Item',
        accessor: 'poLineItem',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? 'NA' : cellInfo?.cell?.value ? cellInfo?.cell?.value : 'NA',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellInfo) =>
          cellInfo?.row?.depth ? cellInfo?.cell?.value : toCapitalize(cellInfo?.cell?.value),
      },
      {
        Header: 'Action',
        id: 'expander',
        Cell: (cellInfo) => {
          return cellInfo?.row?.depth ? (
            <React.Fragment>
              <NavLink
                className={'pl-3'}
                to={{
                  pathname: `/executions/deliveries/${cellInfo.cell.row?.original?.id}`,
                }}>
                View
              </NavLink>
              <NavLink className={'pl-3'} to={'#'}>
                Download
              </NavLink>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavLink className={'pl-3'} to={'#'}>
                Download
              </NavLink>
            </React.Fragment>
          );
        },
      },
    ];
  },
  WORK_ORDER_ITEMS_INCOMING_FINANCE: function ({ vertical, userList, activeCard }) {
    return [
      {
        Header: 'Work Order Id',
        accessor: 'id',
        Cell: (cellInfo) => {
          return (
            <NavLink className={'pl-2'} to={'/work-orders-brandPRO/' + cellInfo?.row?.values?.id}>
              <u>{cellInfo?.cell?.value}</u>
            </NavLink>
          );
        },
        Filter: ColumnFilters,
        isSticky: true,
      },
      {
        Header: 'Brand Name',
        accessor: 'fromCustomerId',
        Cell: (cellInfo) => {
          const { clients } = useSelector(({ account }) => ({
            clients: account.clients,
          }));
          const clientName = clients.find((client) => client.id === cellInfo?.cell?.value)?.name;
          return <span>{clientName}</span>;
        },
        Filter: ColumnFilters,
      },
      {
        Header: 'Recykal SPOC',
        accessor: 'spocID',
        Cell: (cellInfo) => {
          const { clients } = useSelector(({ account }) => ({
            clients: account?.clients,
          }));
          let customer = cellInfo?.row?.original?.fromCustomerId;
          const client = clients?.find((cli) => cli?.id === customer)?.spocName;
          return <span>{client || 'SPOC not assigned'}</span>;
        },
        Filter: ColumnFilters,
      },
      {
        Header: 'WO Qty.',
        accessor: 'totalQty',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          return (
            <div className="text-right">
              <span>
                {cellInfo.value ? `${getNumberFomatWithoutRoundOff(cellInfo?.value)} kg` : 0}{' '}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Pending Qty',
        accessor: 'pendingQty',
        Cell: (cellInfo) => {
          return (
            <div className="text-right">
              <span>
                {cellInfo.value ? `${getNumberFomatWithoutRoundOff(cellInfo?.value)} kg` : 0}
              </span>
            </div>
          );
        },
        Filter: ColumnFilters,
      },
      {
        Header: 'Planned Qty.',
        accessor: 'plannedQty',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          return (
            <div className="text-right">
              <span>
                {cellInfo.value ? `${getNumberFomatWithoutRoundOff(cellInfo?.value)} kg` : 0}{' '}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Fulfilled Qty.',
        accessor: 'fulFilledQty',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          return (
            <div className="text-right">
              <span>
                {cellInfo.value ? `${getNumberFomatWithoutRoundOff(cellInfo?.value)} kg` : 0}{' '}
              </span>
            </div>
          );
        },
      },

      {
        Header: 'Credits Transferred',
        accessor: 'creditsTransfered',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          return (
            <div className="text-right">
              <span>{cellInfo.value ? getNumberFomatWithoutRoundOff(cellInfo?.value) : 0} </span>
            </div>
          );
        },
      },
      {
        Header: 'WO Value',
        accessor: 'totalAmount',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          return (
            <div className="text-right">
              <span>
                &#8377;{' '}
                {cellInfo.value ? getNumberFomatWithoutRoundOff(cellInfo?.value.toFixed(2)) : 0}{' '}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Fulfillment Year',
        accessor: 'fulfillmentYear',
        Cell: (cellInfo) => {
          const startDate =
            cellInfo?.cell?.row?.original?.fulfillmentYearStartDate?.split('-')?.[0];
          const endDate = cellInfo?.cell?.row?.original?.fulfillmentYearEndDate?.split('-')?.[0];

          return <span>{startDate && endDate ? `${startDate} - ${endDate}` : '-'}</span>;
        },
        Filter: '',
      },
      {
        Header: 'PO No.',
        accessor: 'poNumber',
        Filter: ColumnFilters,
      },
      {
        Header: 'Creation Date',
        accessor: 'createdOn',
        Cell: (cellInfo) => {
          return <FormattedDateCell date={cellInfo?.cell?.value} />;
        },
        Filter: ColumnFilters,
      },
      {
        Header: 'PO Date',
        accessor: 'poDate',
        Cell: (cellInfo) => {
          return <FormattedDateCell date={cellInfo?.cell?.value} />;
        },
        Filter: ColumnFilters,
      },
      {
        Header: 'PO Age',
        accessor: 'poAge',
        Cell: (cellInfo) => {
          var poDate = moment(cellInfo.row.original.poDate);
          var currentDate = moment(new Date());
          const poAge = currentDate.diff(poDate, 'days');
          return <span>{cellInfo.row.original.poDate ? `${poAge} days` : '-'} </span>;
        },
        Filter: ColumnFilters,
      },
      {
        Header: 'Estimated Delivery',
        accessor: 'toDate',
        Cell: (cellInfo) => {
          return <FormattedDateCell date={cellInfo?.cell?.value} />;
        },
        Filter: ColumnFilters,
      },
      {
        Header: 'PO Received',
        accessor: 'poDocStatus',
        Cell: (cellInfo) => {
          return <span>{cellInfo?.value || 'N'}</span>;
        },
        Filter: ColumnFilters,
      },

      {
        Header: 'Time Since Rejection',
        accessor: 'rejectionTime',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          return <span>{cellInfo.value || 0}</span>;
        },
      },
      {
        Header: 'Rejected By',
        accessor: 'rejectedBy',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          const rejectedByUser = userList.find((user) => user?.id === cellInfo.value)?.name;
          return <span>{rejectedByUser || '-'}</span>;
        },
      },
      {
        Header: 'Invoiced Quantity',
        accessor: 'invoicedQty',
        Filter: ColumnFilters,
        Cell: (cellInfo) => {
          return (
            <div className="text-right">
              <span>{cellInfo.value ? `${cellInfo.value} kg` : 0}</span>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cellInfo) => {
          return (
            <Badge
              style={{
                backgroundColor: WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.bg,
                color: WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.textColor,
                border: `1px solid ${
                  WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.borderColor
                }`,
                borderRadius: '16px',
                padding: '6px',
                fontSize: '12px',
                fontWeight: 500,
                textAlign: 'center',
                fontFamily: 'Inter',
              }}>
              {WORK_ORDER_DISPLAY_STATUS?.[cellInfo?.cell?.value]?.displayText}
            </Badge>
          );
        },
        Filter: ColumnFilters,
        isSticky: true,
        vertical: vertical,
        activeCard: activeCard,
      },
    ];
  },
};

export const WORK_ORDER_UTILS = {
  transformWorkOrderToTraget: (deliveries) => {
    return deliveries.map((delivery) => {
      return {
        id: delivery.itemId,
        fromDate: delivery.districtId || 0,
        executionMode: delivery.qty,
        toCustomerId: delivery.fulfilledQty,
        draft: toCapitalize(delivery.status),
        creditsTransfered: delivery.creditsAssigned,
        status: toCapitalize(delivery.status),
        createdOn: delivery.estimatedDelivery,
        stateId: delivery.stateId,
        fromCustomerId: delivery.qty,
        workOrderItems: delivery.fulfilledQty,
      };
    });
  },

  transformDeliveryDataToWorkOrderItem: (deliveries) => {
    return deliveries.map((delivery) => {
      return {
        workOrderFor: 'sandeep',
        id: delivery.id,
        stateId: delivery.id,
        districtId: delivery.deliveryDate,
        fulfilledQty: delivery.status,
        qty: delivery.modifiedOn,
        estimatedDelivery: delivery.qty,
        fulfillmentCycle: 0,
        pricePerUnit: 0,
        lineTotal: 0,
        status: 0,
      };
    });
  },
};
