import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { fetchUsers } from './usersThunk';

const initialState = {
  listLight: [],
  isFetchingListLight: false,
  fetchListLightError: null,
  data: [],
};

export const usersSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.isFetchingListLight = true;
      state.fetchListLightError = null;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.isFetchingListLight = false;
      state.fetchListLightError = null;
      state.data = action.payload;
      state.usersMap = keyBy(action.payload, 'id');
    },
    [fetchUsers.rejected]: (state) => {
      state.isFetchingListLight = false;
      state.fetchListLightError = 500;
    },
  },
});

export default usersSlice.reducer;
