import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { convertToMt } from '../../../shared/Utils/Helper';
import '../MappingAccordian.scss';

export default function BranwiseUnplannedPlanned(props) {
  const {
    isDemand,
    plannedData,
    handleClickPlannedState,
    plannedStateId,
    vendorById,
    stateById,
    materialCategories,
  } = props;
  const stateVendorData = plannedData?.vendorPlannedTableData?.find((vendor) => {
    return vendor?.stateId === plannedStateId;
  });
  return (
    <div>
      {isDemand ? (
        <Row>
          <Row style={{ width: '100%' }}>
            <Col>
              <p className="StatewiseWOs" style={{ textAlign: 'left' }}>
                Work Order
              </p>
            </Col>
            <Col>
              <p className="StatewiseWOs" style={{ textAlign: 'right' }}>
                Status
              </p>
            </Col>
          </Row>
          {plannedData?.clientPlannedTableData?.map((data) => {
            return (
              <div
                className={
                  data.stateId === plannedStateId
                    ? 'PlannedStateSelected plannedDemand'
                    : 'plannedDemand'
                }
                onClick={() => handleClickPlannedState(data.stateId)}>
                <Row style={{ width: '100%' }}>
                  <Col>
                    <p className="PlannedStateAndQuantity">{`${
                      materialCategories?.find((cat) => cat?.id === data?.browseNodeId)?.title
                    } / ${data?.itemName} (${convertToMt(data.qty, 3)}MT)`}</p>
                  </Col>
                  <Col xs={'auto'}>
                    <div className="PlannedTag">
                      <p style={{ color: '#bc861c' }}>Draft WO Created</p>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Row>
      ) : (
        <div style={{ margin: '50px 0.68em 0.3em 1.3em' }}>
          {stateVendorData?.vendors?.map((data) => {
            return (
              <div className="plannedSupply">
                <Row style={{ width: '100%' }}>
                  <Col sm="9" md="9" lg="9">
                    <span class="supplyTagText">
                      {`${vendorById[data?.vendorWoLineItem?.toCustomerId]?.name}(${convertToMt(
                        data?.qty,
                        3
                      )} MT):`}{' '}
                      {stateById[data?.vendorWoLineItem?.stateId]?.name}
                    </span>
                  </Col>
                  <Col sm="3" md="3" lg="3">
                    <span className="work-order-tag">
                      <NavLink to={`/work-orders/${data?.vendorWoLineItem?.workOrderId}`}>
                        {data?.vendorWoLineItem?.workOrderId}
                      </NavLink>
                    </span>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
