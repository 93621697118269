import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import EmptyIcon from '../../../assets/img/_App/empty.png';
import { Loader } from '../../../shared/Components';
import { PAGE_CONFIG } from '../../../shared/Constants/Constants';
import PaginationComponent from '../../Customers/PaginationComponent';
import { ColumnSort } from '../../WorkOrder/TableCells/TableCells';
import '../PlanningModule/PlanningModule.scss';

const AdvancePOPlanningTable = (props) => {
  const {
    tableData,
    tableColumns,
    totalCount,
    HeaderColor,
    isRowClickRequired,
    handleTableRowClick,
    tableType,
    setFiltersValue,
    isPaginationRequired,
    handleRowSelection,
    isRowSelectionRequired,
    setSelectedRowIds,
    isSelectedRowIdsRequired,
    selectedRowIds,
    noDataMessage,
    hiddenColumns,
    isInnerArrowActive,
    advancePOLoading,
  } = props;
  const PAGE_SIZE = PAGE_CONFIG.size;
  const data = React.useMemo(() => tableData, [tableData]);
  const columns = React.useMemo(() => tableColumns, [tableColumns]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    state: { pageIndex, filters },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    selectedFlatRows,
    workOrderForm,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: PAGE_SIZE,
        selectedRowIds: selectedRowIds,
        hiddenColumns: hiddenColumns || [],
        filters: [],
      },
      manualPagination: true,
      manualFilters: true,
      autoResetPage: false,
      pageCount: Math.ceil(totalCount / PAGE_SIZE),
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  useEffect(() => {
    if (isPaginationRequired) setFiltersValue((prevData) => ({ ...prevData, page: pageIndex }));
  }, [pageIndex]);

  const filteredRows = rows?.filter((row) => {
    return row?.cells?.every((cell) => {
      const filterValue = cell?.column?.filterValue;
      if (filterValue !== undefined) {
        if (typeof cell?.value === 'string') {
          return String(cell?.value)?.toLowerCase()?.includes(String(filterValue)?.toLowerCase());
        }
        if (typeof cell?.value === 'boolean') {
          return cell?.value === filterValue;
        }
        return cell?.value === filterValue;
      }
      return true;
    });
  });

  useEffect(() => {
    if (isRowSelectionRequired) {
      if (!isEmpty(selectedFlatRows)) {
        handleRowSelection(selectedFlatRows);
        if (isSelectedRowIdsRequired) {
          let selectedRowIds = {};
          for (let selectedRows of selectedFlatRows) {
            selectedRowIds = {
              ...selectedRowIds,
              [selectedRows?.index]: true,
            };
          }
          setSelectedRowIds(selectedRowIds);
        }
      } else handleRowSelection(selectedFlatRows);
    }
  }, [selectedFlatRows?.length]);

  const isRowSelected = (value, type) => {
    return value?.original?.isRowSelected ? value?.original?.[type] : false;
  };

  return (
    <div>
      <React.Suspense fallback={<Loader />}>
        <div className="mt-5">
          <table {...getTableProps()} style={{ width: '100%' }} className="sticky-table mt-2 ">
            <thead className="planning-thead-light">
              {headerGroups.map((headerGroup) => (
                <>
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                    className={
                      HeaderColor ? 'tableHeader planning-thead-main' : 'planning-thead-main'
                    }>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={column.id}
                          style={{ padding: '2px 8px' }}
                          className={column.isSticky ? 'sticky' : ''}>
                          <div className={'d-flex flex-row align-items-center'}>
                            {column.render('Header')}

                            {column.canSort && <ColumnSort column={column} />}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                    {headerGroup.headers.map((column) => {
                      const renderFilter = () => {
                        return column.render('Filter');
                      };
                      return (
                        <th className={column.isSticky ? 'sticky' : ''}>
                          <div>{column.canFilter ? renderFilter() : ''}</div>
                        </th>
                      );
                    })}
                  </tr>
                </>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!advancePOLoading && filteredRows?.length === 0 ? (
                <td colSpan={10}>
                  <div className={'align-items-center d-flex flex-column justify-content-center '}>
                    <img style={{ width: '9rem' }} src={EmptyIcon} />
                    <p className="tableNoDataText">{noDataMessage || 'No data found'}</p>
                  </div>
                </td>
              ) : !advancePOLoading ? (
                filteredRows?.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={row?.id}
                      onClick={() =>
                        workOrderForm?.setFieldValue('advancePO', {
                          ...workOrderForm?.values?.advancePO,
                          isRowSelected: !workOrderForm?.values?.advancePO[row?.id]?.isRowSelected,
                        })
                      }
                      className="tableData"
                      style={{
                        backgroundColor: isRowSelected(row, 'backgroundColor'),
                        // color: isRowSelected(row, 'color'),
                      }}>
                      {row.cells.map((cell, idx) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={cell.column.id}
                            className={
                              cell.column.isSticky ? 'sticky planning_table_bg_inherit' : ''
                            }>
                            <div className="d-flex">
                              {idx !== 0 && (
                                <span
                                  className="verticalSeperator"
                                  style={{
                                    color: row?.original?.isRowSelected && row?.original?.color,
                                  }}></span>
                              )}
                              <div className="dataSpacing">{cell.render('Cell')}</div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <td colSpan={10}>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <Loader />
                  </div>
                </td>
              )}
            </tbody>
          </table>
        </div>
        {pageOptions.length > 1 && isPaginationRequired ? (
          <PaginationComponent
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
            currentPage={pageIndex}
            numberOfPages={pageOptions.length}
            totalCount={totalCount}
          />
        ) : (
          <p className="mt-6 p-4" style={{ fontFamily: 'Inter' }}>
            Total Count : {totalCount}
          </p>
        )}
      </React.Suspense>
    </div>
  );
};

export default AdvancePOPlanningTable;
