import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const fetchUsers = async () => {
  return await API.get(APIEndpoints.GET_USER);
};

export const usersApi = {
  fetchUsers,
};
