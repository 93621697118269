import { combineReducers } from 'redux';
import { AccountReducer } from './Account';
import { AllPermissionsReducer } from './AllPermissions';
import { BrandReducers } from './Brand';
import { BrowseNodeReducers } from './BrowseNode';
import { ExecutionReducers } from './Execution';
import { PermissionsReducer } from './Permissions';
import { RegionReducers } from './Region';
import { ReportReducers } from './Reports';
import { ServiceProviderReducers } from './ServiceProvider';
import { TargetReducers } from './Target';
import { UserReducers } from './User';
import { usersList } from './Users/Index';

const reducer = combineReducers({
  target: TargetReducers,
  browseNodes: BrowseNodeReducers,
  region: RegionReducers,
  account: AccountReducer,
  brand: BrandReducers,
  serviceProvider: ServiceProviderReducers,
  execution: ExecutionReducers,
  users: UserReducers,
  usersList: usersList,
  report: ReportReducers,
  permissions: PermissionsReducer,
  allPermissions: AllPermissionsReducer,
});

export default reducer;
