import { isEmpty, sumBy } from 'lodash';
import React from 'react';
import { InputCell } from '../../features/WorkOrder/TableCells/TableCells';
import { useRegions } from '../../hooks';
import { useServiceProviders } from '../../hooks/useCustomers';

export const PlanningConstants = {
  AdvancePOColumns: function (props) {
    const { workOrderForm, supplyTab, serviceProviderById } = props;
    const cellHandler = (rowIndex, columnId, value, cellValue) => {
      const modifiedData = workOrderForm?.values?.[supplyTab].map((row, index) => {
        if (index === rowIndex) {
          // eslint-disable-next-line default-case
          switch (columnId) {
            case 'allocate':
              const selectedClientData = workOrderForm?.values?.clientData?.find(
                (data) => data?.isRowSelected
              );
              const unSelectedVendors = cellValue?.rows?.filter(
                (_data, cellValueIndex) => cellValueIndex !== rowIndex
              );
              const vendorsAllocatedQty =
                sumBy(unSelectedVendors?.map((data) => Number(data?.original?.allocate))) +
                Number(value);

              return {
                ...row,
                allocate: value,
                isDemandLess: value ? selectedClientData?.qty < value : false,
                isVendorAvailableLess: value ? row?.available < value : false,
                isVendorSummationAllocateGreater: value
                  ? selectedClientData?.qty < vendorsAllocatedQty
                  : false,
                isRowSelected: Number(value) && !isEmpty(value),
              };
          }
        } else {
          const rowValue = cellValue?.rows?.find(
            (_data, cellValueIndex) => cellValueIndex !== rowIndex && index === cellValueIndex
          );

          return {
            ...row,
            isRowSelected: rowValue?.original?.isRowSelected,
            allocate: rowValue?.original?.allocate,
          };
        }
      });
      // const sortedBasedOnMarginVendorData = sortBy(modifiedData, 'margin');

      workOrderForm.setFieldValue(
        `${supplyTab}`,
        modifiedData?.map((data, index) => ({ ...data, id: index + 1 }))
      );
    };
    // const regions = useRegions();
    // const serviceProviders = useServiceProviders();
    return [
      {
        Header: 'Rank',
        accessor: 'id',
        Cell: (cellInfo) => {
          return <p>{Number(cellInfo?.row?.id) + 1}</p>;
        },
      },
      {
        Header: 'Recycler List',
        accessor: 'customerId',
        Cell: (cellInfo) => {
          const serviceProviders = useServiceProviders();
          let value = cellInfo?.cell?.value;
          let recycler = '';
          if (Array.isArray(serviceProviders)) {
            recycler = serviceProviders?.find((item) => item?.id === value)?.name;
          }
          return <p> {recycler ? recycler : 'Not Applicable'}</p>;
        },
      },
      { Header: 'Total Qty (kg) ', accessor: 'avaliableQty' },
      {
        Header: 'Traceability ',
        accessor: 'traceability',
        Cell: (cellInfo) => {
          return <p> {cellInfo?.cell?.value ? 'Yes' : 'No'}</p>;
        },
      },
      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          const regions = useRegions();
          let stateId = regions?.find((item) => item.id === cellInfo?.cell?.value)?.name;
          return <p>{stateId ? stateId : 'NA'}</p>;
        },
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        Cell: (cellInfo) => {
          return (
            <div style={{ minWiidth: '20px' }}>
              <p>{cellInfo?.cell?.value}</p>
            </div>
          );
        },
      },
      {
        Header: 'Margin',
        accessor: 'margin',
        Cell: (cellInfo) => {
          return (
            <div style={{ minWidth: '20px' }}>
              <p>{cellInfo?.cell?.value?.toFixed(1)}</p>
            </div>
          );
        },
      },
      {
        Header: 'Allocated (kg)',
        accessor: 'allocate',
        Cell: (cellInfo) => {
          const isAllocateQuantityExceeding =
            cellInfo?.row?.original?.isDemandLess ||
            cellInfo?.row?.original?.isVendorAvailableLess ||
            cellInfo?.row?.original?.isVendorSummationAllocateGreater;
          return (
            <>
              <div
                className={'d-inline-flex  align-items-center'}
                style={{
                  minWidth: '60px',
                  border: isAllocateQuantityExceeding && '1px solid red',
                  marginTop: isAllocateQuantityExceeding && '10px',
                }}>
                <InputCell
                  cellInfo={cellInfo}
                  controlName="qty"
                  required={false}
                  inputType="number"
                  step={'any'}
                  placeholder={'Allocate qty'}
                  updateHandler={cellHandler}
                  min={1}
                  type="number"
                />
              </div>
              {isAllocateQuantityExceeding && (
                <p style={{ color: '#FF0000', marginBottom: '0px' }}>
                  {cellInfo?.row?.original?.isDemandLess
                    ? `Supply shouldn't be greater than the demand`
                    : cellInfo?.row?.original?.isVendorAvailableLess
                    ? `Supply shouldn't be more than Available capacity`
                    : cellInfo?.row?.original?.isVendorSummationAllocateGreater
                    ? `Sum of the supply shouldn't be greater than the demand`
                    : ''}
                </p>
              )}
            </>
          );
        },
      },
      // {
      //   Header: 'Vendor Name',
      //   accessor: 'vendorName',
      //   Cell: (cellInfo) => {
      //     return (
      //       <>
      //         {cellInfo?.row?.original?.isServiceProvider ? (
      //           <div>
      //             <span>{cellInfo.cell.value}</span>
      //             <div>
      //               <i style={{ fontSize: '12px', marginTop: 0 }}>
      //                 {cellInfo?.row?.original?.recyclerName}
      //               </i>
      //             </div>
      //           </div>
      //         ) : (
      //           <p>{cellInfo?.cell?.value}</p>
      //         )}
      //       </>
      //     );
      //   },
      // },

      // {
      //   Header: 'Available (kg)',
      //   accessor: 'available',
      //   Cell: (cellInfo) => {
      //     return (
      //       <Link
      //         to={{
      //           pathname: `/service-provider/${cellInfo?.row?.original?.customerId}?vendorType=${
      //             supplyTab === 'recyclers' ? 'PWP' : 'SERVICE_PROVIDER'
      //           }&vendorName=${cellInfo?.row?.original?.vendorName}&id=${
      //             cellInfo?.row?.original?.customerId
      //           }`,
      //         }}
      //         target="_blank">
      //         {cellInfo?.cell?.value}
      //       </Link>
      //     );
      //   },
      // },
    ];
  },
};

export const AdvancePOSupplyTabs = {
  Recyclers: 'adrecyclers',
  'Service Providers': 'adserviceProviders',
};
