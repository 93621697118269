import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import EmptyIcon from '../../../assets/img/_App/empty.png';
import { Loader } from '../../../shared/Components';
import { PAGE_CONFIG } from '../../../shared/Constants/Constants';
import PaginationComponent from '../../Customers/PaginationComponent';
import { ColumnSort } from '../../WorkOrder/TableCells/TableCells';
import '../PlanningModule/PlanningModule.scss';
export default function PlanningTable(props) {
  const {
    tableData,
    tableColumns,
    totalCount,
    HeaderColor,
    isRowClickRequired,
    handleTableRowClick,
    tableType,
    setFiltersValue,
    isPaginationRequired,
    handleRowSelection,
    isRowSelectionRequired,
    setSelectedRowIds,
    isSelectedRowIdsRequired,
    selectedRowIds,
    noDataMessage,
    hiddenColumns,
    isInnerArrowActive,
    workOrderForm,
    setFirstRowData,
    suppliesLoading,
  } = props;
  const PAGE_SIZE = PAGE_CONFIG.size;
  const data = React.useMemo(() => tableData, [tableData]);
  const columns = React.useMemo(() => tableColumns, [tableColumns]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    state: { pageIndex },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: PAGE_SIZE,
        selectedRowIds: selectedRowIds,
        hiddenColumns: hiddenColumns || [],
      },
      manualPagination: true,
      manualFilters: true,
      autoResetPage: false,
      pageCount: Math.ceil(totalCount / PAGE_SIZE),
    },
    useSortBy,
    usePagination,
    useRowSelect
  );
  useEffect(() => {
    if (isPaginationRequired) setFiltersValue((prevData) => ({ ...prevData, page: pageIndex }));
  }, [pageIndex]);

  useEffect(() => {
    if (isRowSelectionRequired) {
      if (!isEmpty(selectedFlatRows)) {
        handleRowSelection(selectedFlatRows);
        if (isSelectedRowIdsRequired) {
          let selectedRowIds = {};
          for (let selectedRows of selectedFlatRows) {
            selectedRowIds = {
              ...selectedRowIds,
              [selectedRows?.index]: true,
            };
          }
          setSelectedRowIds(selectedRowIds);
        }
      } else handleRowSelection(selectedFlatRows);
    }
  }, [selectedFlatRows?.length]);

  const isRowSelected = (value, type) =>
    value?.original?.isRowSelected ? value?.original?.[type] : false;

  // useEffect(() => {
  //   if (isInnerArrowActive?.isArrowActive) {
  //     let row = page[0];
  //     if (row && row?.original?.browseNodeId && row?.original?.pricePerUnit) {
  //       handleTableRowClick(row, tableType);
  //     }
  //   }
  // }, [isInnerArrowActive?.isArrowActive]);

  useEffect(() => {
    if (isInnerArrowActive?.isArrowActive) {
      let row = rows[0];
      if (row && row?.original?.browseNodeId && row?.original?.pricePerUnit) {
        setFirstRowData(row);
      }
    }
  }, [isInnerArrowActive]);

  return (
    <div>
      {totalCount > 0 ? (
        <React.Suspense fallback={<Loader />}>
          <div className="mt-5">
            <table {...getTableProps()} style={{ width: '100%' }} className="sticky-table mt-2">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className={HeaderColor ? 'tableHeader' : 'none'}>
                    {headerGroup.headers.map((column, idx) => (
                      <th
                        key={column.id}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={column.isSticky ? 'sticky' : ''}>
                        <div className="d-flex align-items-center">
                          {idx !== 0 && HeaderColor && <span className="verticalSeperator"></span>}
                          <div className="dataSpacing">{column.render('Header')}</div>
                          <span>{column.canSort ? <ColumnSort column={column} /> : null}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {!suppliesLoading ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={row.id}
                        onClick={() => isRowClickRequired && handleTableRowClick(row, tableType)}
                        className="tableData"
                        style={{
                          backgroundColor: isRowSelected(row, 'backgroundColor'),
                          // color: isRowSelected(row, 'color'),
                        }}>
                        {row.cells.map((cell, idx) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={cell.column.id}
                              className={
                                cell.column.isSticky ? 'sticky planning_table_bg_inherit' : ''
                              }>
                              <div className="d-flex">
                                {idx !== 0 && (
                                  <span
                                    className="verticalSeperator"
                                    style={{
                                      color: row?.original?.isRowSelected && row?.original?.color,
                                    }}></span>
                                )}
                                <div className="dataSpacing">{cell.render('Cell')}</div>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <td colSpan={10}>
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <Loader />
                    </div>
                  </td>
                )}
              </tbody>
            </table>
          </div>
          {pageOptions.length > 1 && isPaginationRequired ? (
            <PaginationComponent
              canPreviousPage={canPreviousPage}
              previousPage={previousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              gotoPage={gotoPage}
              currentPage={pageIndex}
              numberOfPages={pageOptions.length}
              totalCount={totalCount}
            />
          ) : (
            <p className="mt-4">Total Count : {totalCount}</p>
          )}
        </React.Suspense>
      ) : (
        <div className={'align-items-center d-flex flex-column justify-content-center w-100 '}>
          <img style={{ width: '9rem' }} src={EmptyIcon} />
          <p className="tableNoDataText">{noDataMessage || 'No data found'}</p>
        </div>
      )}
    </div>
  );
}
