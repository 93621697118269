import React from 'react';
import { ADVANCE_PO_TABS } from '../../shared/Constants/Constants';
import PlanningTabs from './PlanningTabs';

const AdvancePOTabs = (props) => {
  const { workOrderForm, setAdvanceSupplyTab, advanceSupplyTab } = props;
  const handleAdvancePOTabs = (eventKey) => {
    const modifiedData = workOrderForm?.values?.['advancePO']?.map((item) => ({
      ...item,
      isRowSelected: false,
      allocate: null,
    }));
    if (modifiedData) {
      workOrderForm.setFieldValue('advancePO', modifiedData);
    }
    setAdvanceSupplyTab(eventKey);
  };
  return (
    <div className="ml-5 mb-0">
      <PlanningTabs
        tabArray={ADVANCE_PO_TABS}
        handleTabSelect={handleAdvancePOTabs}
        activeKey={advanceSupplyTab}
      />
    </div>
  );
};

export default AdvancePOTabs;
