import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  changePassword,
  fetchMyClients,
  getAccount,
  getGlobalNotifications,
  getTimelineLogs,
  setNotificationTapped,
  updateContactPerson,
  updateDevice,
  updateProfile,
  updateProfileInfo,
  uploadLogo,
} from './accountThunks';

const InitialYear = 2017;
const CurrentYear = Number(new Date().getFullYear());
const CurrentMonth = Number(new Date().getMonth());
const YearGap = (CurrentMonth < 3 ? CurrentYear : CurrentYear + 1) - InitialYear;
const CURRENT_YEAR = { selectedFinYear: '', currentYear: '', previousFinYear: '' };

function getFinYear() {
  const YEARS = [];

  for (let i = 0; i < YearGap; i++) {
    const start = InitialYear + i;
    const end = InitialYear + i + 1;
    const OBJ = {
      label: `April ${start} to March ${end}`,
      value: {
        fromDate: `${start}-03-31T18:30:00.000Z`,
        toDate: `${end}-03-31T18:29:29.000Z`,
      },
    };

    if (i === YearGap - 1) {
      CURRENT_YEAR['previousFinYear'] = OBJ;
    }

    if (i + 1 === YearGap) {
      CURRENT_YEAR['selectedFinYear'] = OBJ;
      CURRENT_YEAR['currentYear'] = OBJ;
    }

    YEARS.push(OBJ);
  }

  return YEARS;
}
function getFulfilmentYear() {
  const YEARS = [];

  for (let i = 0; i < YearGap; i++) {
    const start = InitialYear + i;
    const end = InitialYear + i + 1;
    const OBJ = {
      label: `FY ${start} to FY ${end}`,
      value: {
        fromDate: `${start}-03-31T18:30:00.000Z`,
        toDate: `${end}-03-31T18:29:29.000Z`,
      },
    };

    if (i === YearGap - 1) {
      CURRENT_YEAR['previousFinYear'] = OBJ;
    }

    if (i + 1 === YearGap) {
      CURRENT_YEAR['selectedFinYear'] = OBJ;
      CURRENT_YEAR['currentYear'] = OBJ;
    }

    YEARS.push(OBJ);
  }

  return YEARS;
}

const initialState = {
  isAuthenticate: false,
  user: null,
  profile: null,
  device: {},
  updateProfileError: null,
  updateContactPersonError: null,
  isLoading: false,
  uploadLogoError: null,
  error: null,
  financialYears: getFinYear(),
  fulfilmentYears: getFulfilmentYear(),
  isUsingFulfilment: true || JSON.parse(localStorage.getItem('isUsingFulfilment')),
  selectedFinancialYear: CURRENT_YEAR.selectedFinYear,
  selectedFulfilmentYear: CURRENT_YEAR.selectedFinYear,
  currentYear: CURRENT_YEAR.currentYear,
  previousFinancialYear: CURRENT_YEAR.previousFinYear,
  clients: [],
  isLoadingClients: false,
  globalNotifications: [],
  notiLoading: true,
  timelineEvents: [],
  timelineEventsLoading: true,
};

export const AccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setFinancialYear: (state, action) => {
      state.selectedFinancialYear = action.payload;
    },
    setFulfilmentYear: (state, action) => {
      state.selectedFulfilmentYear = action.payload;
    },
    setIsUsingFulfilment: (state, action) => {
      state.isUsingFulfilment = action.payload;
    },
  },
  extraReducers: {
    [getAccount.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    [getAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticate = true;
      state.user = action.payload;
      state.error = false;
    },
    [getAccount.rejected]: (state) => {
      state.isLoading = false;
      state.isAuthenticate = false;
      state.error = 500;
    },
    [updateDevice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.device = action.payload;
    },
    [fetchMyClients.pending]: (state) => {
      state.isLoadingClients = true;
    },
    [fetchMyClients.fulfilled]: (state, action) => {
      state.isLoadingClients = false;
      state.clients = action.payload;
    },
    [fetchMyClients.rejected]: (state) => {
      state.isLoadingClients = false;
    },
    [updateProfileInfo.pending]: (state) => {
      state.isLoading = true;
      state.updateProfileError = null;
    },
    [updateProfileInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updateProfileError = null;
      state.user = action.payload;
    },
    [updateProfileInfo.rejected]: (state) => {
      state.isLoading = false;
      state.updateProfileError = 500;
    },
    [updateContactPerson.pending]: (state) => {
      state.isLoading = true;
      state.updateContactPersonError = null;
    },
    [updateContactPerson.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updateContactPersonError = null;
      state.user = action.payload;
    },
    [updateContactPerson.rejected]: (state) => {
      state.isLoading = false;
      state.updateContactPersonError = 500;
    },
    [uploadLogo.pending]: (state) => {
      state.isLoading = true;
      state.uploadLogoError = null;
    },
    [uploadLogo.fulfilled]: (state) => {
      state.isLoading = false;
      state.uploadLogoError = null;
    },
    [uploadLogo.rejected]: (state) => {
      state.isLoading = false;
      state.uploadLogoError = 500;
    },
    [changePassword.pending]: (state) => {
      state.isLoading = true;
      state.uploadLogoError = null;
    },
    [changePassword.fulfilled]: (state) => {
      state.isLoading = false;
      state.uploadLogoError = null;
    },
    [changePassword.rejected]: (state) => {
      state.isLoading = false;
      state.uploadLogoError = 500;
    },
    [updateProfile.pending]: (state) => {
      state.isLoading = true;
      state.uploadLogoError = null;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.uploadLogoError = null;
      state.profile = action.payload;
    },
    [updateProfile.rejected]: (state) => {
      state.isLoading = false;
      state.uploadLogoError = 500;
    },
    [getGlobalNotifications.pending]: (state) => {
      state.notiLoading = true;
    },
    [getGlobalNotifications.fulfilled]: (state, action) => {
      state.notiLoading = false;
      state.globalNotifications = action.payload;
    },
    [getGlobalNotifications.rejected]: (state) => {
      state.notiLoading = false;
    },
    [setNotificationTapped.fulfilled]: (state, action) => {
      const newNotifications = state.globalNotifications?.map((not) => {
        if (not.deliveryLog && not.deliveryLog.id === action.payload) {
          not.deliveryLog.tappedAt = moment().toISOString();
        }
        return not;
      });
      state.globalNotifications = newNotifications;
    },
    [getTimelineLogs.pending]: (state) => {
      state.timelineEventsLoading = true;
    },
    [getTimelineLogs.fulfilled]: (state, action) => {
      state.timelineEventsLoading = false;
      state.timelineEvents = action.payload;
    },
    [getTimelineLogs.rejected]: (state) => {
      state.timelineEvents = false;
    },
  },
});

export const { setFinancialYear, setFulfilmentYear, setIsUsingFulfilment } = AccountSlice.actions;
export const globalNotifications = (state) => state.account.globalNotifications;

export default AccountSlice.reducer;
